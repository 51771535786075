<template>
  <div class="container">
    <dd-card title="">
      <dd-form
        :form-config="checkProp.pageType == 'check' ? formList : formList1"
        ref="checkEditPopup"
        formRef="checkEditPopup"
        :flex="1"
        labelWidth="180px"
      ></dd-form>
    </dd-card>
  </div>
</template>
  
  <script>
export default {
  name: "checkEditPopup",
  props: ["checkProp"],
  data() {
    return {
      formList: [
        {
          label: "金蝶付款单编号",
          value: "paymentfnumber",
          inputType: "text",
          placeholderText: "金蝶付款单编号",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "金蝶付款单名称",
          value: "paymentfname",
          placeholderText: "金蝶付款单名称",
          inputType: "text",
          defaultValue: "",
          disabled: true,
        },
        {
          label: "归属部门",
          value: "deptName",
          inputType: "text",
          placeholderText: "归属部门",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "关联实付金额",
          value: "allotmentAmount",
          inputType: "text",
          placeholderText: "关联实付金额",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "复核金额",
          value: "checkAmount",
          placeholderText: "请输入复核金额",
          disabled: false,
          inputType: "text",
          defaultValue: "",
          rules: [
            {
              required: true,
              message: "请输入复核金额",
              trigger: "blur",
            },
            {
              pattern: /^(-?\d*\.\d{1,2}|-?\d{1,9})$/,
              required: true,
              message: "请输入正确的格式",
              trigger: "blur",
            },
          ],
        },
      ],
      formList1: [
        {
          label: "金蝶付款单编号",
          value: "paymentfnumber",
          inputType: "text",
          placeholderText: "金蝶付款单编号",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "金蝶付款单名称",
          value: "paymentfname",
          placeholderText: "金蝶付款单名称",
          inputType: "text",
          defaultValue: "",
          disabled: true,
        },
        {
          label: "归属部门",
          value: "deptName",
          inputType: "text",
          placeholderText: "归属部门",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "关联实付金额",
          value: "allotmentAmount",
          inputType: "text",
          placeholderText: "关联实付金额",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "修改后复核金额",
          value: "checkAmount",
          placeholderText: "请输入修改后复核金额",
          disabled: false,
          inputType: "text",
          defaultValue: "",
          rules: [
            {
              required: true,
              message: "请输入修改后复核金额",
              trigger: "blur",
            },
            {
              pattern: /^(-?\d*\.\d{1,2}|-?\d{1,9})$/,
              required: true,
              message: "请输入正确的格式",
              trigger: "blur",
            },
          ],
        },
        {
          label: "修改原因",
          placeholderText: "请输入修改原因",
          disabled: false,
          value: "remark",
          inputType: "text",
          type: "textarea",
          defaultValue: "",
          rows: "2",
        },
      ],
    };
  },
  created() {},
  mounted() {
    if (this.checkProp.pageType == "check") {
      this.formList.forEach((item) => {
        if (
          (item.value == "allotmentAmount" || item.value == "checkAmount") &&
          this.checkProp[item.value] != null
        ) {
          if (item.value == "checkAmount") {
            item.defaultValue = this.checkProp["allotmentAmount"].toString();
          } else {
            item.defaultValue = this.checkProp[item.value].toString();
          }
        } else {
          item.defaultValue = this.checkProp[item.value];
        }
      });
    } else {
      this.formList1.forEach((item) => {
        if (
          (item.value == "allotmentAmount" || item.value == "checkAmount") &&
          this.checkProp[item.value] != null
        ) {
          item.defaultValue = this.checkProp[item.value].toString();
        } else {
          item.defaultValue = this.checkProp[item.value];
        }
      });
    }
  },
  methods: {},
};
</script>
  
  <style lang='less' scoped>
/deep/ .el-range-editor.is-disabled {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}

/deep/ .el-range-editor.is-disabled input {
  color: @defaultFontColor;
}

/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}

/deep/.el-textarea.is-disabled .el-textarea__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
  resize: none;
}
</style>