<template>
  <dd-card>
    <dd-table
      :columns="tableColumns"
      :data="tableData"
      isSort
      border
      :topBtn="false"
      :isSelection="false"
    >
    </dd-table>
  </dd-card>
</template>
  
  <script>
import { getApi } from "@/api/request";
export default {
  props: ["recordProp"],
  data() {
    return {
      tableHeight: null,
      tableColumns: [
        {
          title: "修改者",
          key: "modifierName",
          align: "center",
          overflow: true,
        },
        {
          title: "修改日期",
          key: "modifyDate",
          align: "center",
          overflow: true,
        },
        {
          title: "修改前复核金额",
          key: "checkAmount",
          align: "center",
          overflow: true,
        },
        {
          title: "修改后复核金额",
          key: "checkdAmount",
          align: "center",
          overflow: true,
        },
        {
          title: "修改原因",
          key: "modifyReason",
          align: "center",
          overflow: true,
        },
      ],
      tableData: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
    this.getTableData();
  },
  methods: {
    // 获取表格列表数据
    getTableData() {
      getApi(
        `/contract/contractselfpurchasepaymentrelation/record?id=${this.recordProp.id}`
      ).then((res) => {
        if (res.data.code == 0) {
          this.tableData = res.data.data;
        }
      });
    },
  },
};
</script>
  
  <style></style>
  