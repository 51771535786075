<template>
  <div class="main-container">
    <div class="head_wrap">
      <dd-card title="条件搜索" spacer>
        <dd-search-form
          :form-config="formList"
          :labelWidth="'190px'"
          @search-click="handleSearchEvent"
          ref="ddSearchForm"
        ></dd-search-form>
      </dd-card>
    </div>
    <dd-card title="详细记录表" :height="tableHeight">
      <dd-table
        :columns="columnsList"
        :data="tableData"
        border
        :isSelection="true"
        show-page
        :loading="loading"
        @select-change="handleSelectionChange"
        ref="dTableRef"
      >
        <template #table-btn>
          <dd-button type="primary" icon="add" size="medium" @click="handleAdd"
            >新增记录
          </dd-button>
          <dd-button
            type="info"
            icon="export"
            size="medium"
            @click="handleExport"
            >导出
          </dd-button>
        </template>
        <template #table-btn-right>
          <el-button
            icon="el-icon-s-operation"
            circle
            type="info"
            size="small"
            @click="drawerVisible = true"
            title="列筛选"
          ></el-button>
        </template>
        <template #table-pagination>
          <dd-pagination
            v-show="total > 0"
            :total="total"
            :page-size="size"
            :page-number="current"
            @page-change="handlePageChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>

    <!-- 新增记录 -->

    <dd-popup
      v-if="addEditPopupVisible"
      width="65%"
      height="75%"
      showBtn
      :cancelBtn="true"
      :saveBtn="false"
      :submitBtn="false"
      :show="addEditPopupVisible"
      :title-name="addEditPopupTitle"
      @popup-close="handleClose"
    >
      <dd-button
        slot="button"
        size="medium"
        v-if="pageProp.pageType != 'detail'"
        type="primary"
        @click="handleSubmit(0)"
        >提交并填写下一条</dd-button
      >
      <dd-button
        slot="button"
        size="medium"
        v-if="pageProp.pageType != 'detail'"
        type="primary"
        @click="handleSubmit(1)"
        >提交</dd-button
      >
      <addEditPopup
        ref="addEditPopup"
        :pageProp="pageProp"
        slot="main"
      ></addEditPopup>
    </dd-popup>

    <dd-filter-drawer
      v-if="drawerVisible"
      width="30%"
      title-name="列筛选"
      :show="drawerVisible"
      :tableColumns="tableColumns"
      :columns="columnsList"
      @popup-close="drawerVisible = false"
      @handleColumnsChange="handleColumnsChange"
    >
    </dd-filter-drawer>
  </div>
</template>
  
  <script>
import { delApi, postApi, putApi, loadApi } from "@/api/request";
import { downloadBinaryFile } from "@/utils/index";
import addEditPopup from "./components/addEditPopup.vue";
export default {
  name: "recordDetail",
  $dynamicRoute: {
    name: "详细记录表 ",
    path: "/contact/recordDetail/index",
  },
  components: { addEditPopup },
  data() {
    return {
      loading: false,
      addEditPopupVisible: false,
      saveBtn: false,
      pageProp: {},
      addEditPopupTitle: "",
      formList: [
        {
          label: "销售合同编号",
          value: "saleContractCode",
          inputType: "text",
          placeholderText: "请输入销售合同编号进行搜索",
        },
        {
          label: "销售合同名称",
          value: "saleContractName",
          inputType: "text",
          placeholderText: "请输入销售合同名称进行搜索",
        },
        {
          label: "销售合同甲方",
          value: "partAId",
          inputType: "text",
          placeholderText: "请输入销售合同甲方进行搜索",
        },
        {
          label: "自建采购合同编号",
          value: "selfContractCode",
          inputType: "text",
          placeholderText: "请输入自建采购合同编号进行搜索",
        },
        {
          label: "自建采购合同名称",
          value: "selfContractName",
          inputType: "text",
          placeholderText: "请输入自建采购合同名称进行搜索",
        },
      ],
      drawerVisible: false,
      tableColumns: [
        {
          title: "单据编号",
          key: "code",
          align: "center",
          overflow: true,
        },
        {
          title: "销售合同编号",
          key: "saleContractCode",
          align: "center",
          overflow: true,
        },
        {
          title: "销售合同名称",
          key: "saleContractName",
          align: "center",
          overflow: true,
        },
        {
          title: "销售合同甲方",
          key: "partAId",
          align: "center",
          overflow: true,
        },
        {
          title: "已用金额",
          key: "amount",
          align: "center",
          overflow: true,
        },
        {
          title: "备注",
          key: "remark",
          align: "center",
          overflow: true,
        },
        {
          title: "自建采购合同编号",
          key: "selfContractCode",
          align: "center",
          overflow: true,
        },
        {
          title: "自建采购合同名称",
          key: "selfContractName",
          align: "center",
          overflow: true,
        },
        {
          title: "记录日期",
          key: "recordDate",
          align: "center",
          overflow: true,
        },
        {
          title: "最新修改日期",
          key: "modifyDate",
          align: "center",
          overflow: true,
        },
        {
          title: "操作",
          align: "center",
          fixed: "right",
          overflow: true,
          width: 150,
          event: [
            {
              state: "default",
              label: "编辑",
              type: "warning",
              width: 100,
              method: (row) => {
                this.handleEdit(row);
              },
            },
            {
              state: "default",
              type: "danger",
              label: "删除",
              width: 100,
              method: (row) => {
                this.handleDelete(row);
              },
            },
            {
              state: "default",
              label: "查看",
              width: 100,
              method: (row) => {
                this.handleRowDetail(row);
              },
            },
          ],
        },
      ],
      tableData: [],
      columnsList: [],
      tableHeight: null,
      saveBtn: false,
      // 当前页码
      current: 1,
      // 总条数
      total: 0,
      // 每页的数据条数
      size: 10,
      searchData: {},
      selectTable: [], // 表格勾选集合
    };
  },
  created() {},
  mounted() {
    this.columnsList = [...this.tableColumns];
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
    this.getTableData();
  },
  methods: {
    getTableData() {
      postApi(
        `/contract/specialrecord/page/${this.current}/${this.size}`,
        this.searchData
      ).then((res) => {
        if (res.data.code == 0) {
          this.tableData = res.data.data.records;
          this.total = res.data.data.total;
        }
      });
    },
    handleSearchEvent(e) {
      this.current = 1;
      this.searchData = e;
      this.getTableData();
    },
    handlePageChange(pageSize,pageNumber) {
      this.size = pageSize;
      this.current = pageNumber;
      this.getTableData();
    },
    handleColumnsChange(val) {
      let list = new Set();
      this.tableColumns.map((item) => {
        val.map((itm) => {
          if (item.event || item.key == itm.key) {
            list.add(item);
          }
        });
      });
      this.columnsList = [...list];
    },
    handleAdd() {
      this.addEditPopupTitle = "新增";
      this.pageProp.pageType = "add";
      this.pageProp.id = null;
      this.addEditPopupVisible = true;
    },
    handleSelectionChange(e) {
      this.selectTable = e;
    },
    handleExport() {
      let params = {
        ...this.searchData,
        ids: this.selectTable.map((item) => {
          return item.id;
        }),
      };
      loadApi(`/contract/specialrecord/record/detail/export`, params).then(
        (res) => {
          let { data } = res;
          this.$refs.dTableRef.$refs.baseTable.clearSelection();
          this.selectTable = [];
          downloadBinaryFile(
            data,
            decodeURIComponent(
              res.headers["content-disposition"].split(";")[1].split("=")[1]
            )
          );
          this.$message.success("导出成功");
        }
      );
    },
    handleEdit(row) {
      this.addEditPopupTitle = "编辑";
      this.addEditPopupVisible = true;
      this.pageProp.id = row.id;
      this.pageProp.pageType = "edit";
    },
    handleRowDetail(row) {
      this.addEditPopupTitle = "查看";
      this.addEditPopupVisible = true;
      this.pageProp.id = row.id;
      this.pageProp.pageType = "detail";
    },
    handleClose() {
      this.addEditPopupVisible = false;
    },
    handleDelete(row) {
      this.$confirm(`确认删除该数据吗？`, "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      }).then(() => {
        delApi(`/contract/specialrecord?id=${row.id}`).then((res) => {
          if (res.data.code == 0) {
            this.$message.success("操作成功");
          } else {
            this.$message.warning("操作失败，请稍后重试");
          }
          this.getTableData();
        });
      });
    },
    handleSubmit(type) {
      let flag = false;
      this.$refs.addEditPopup.$refs.addPopForm.$refs.addPopForm.validate(
        (valid) => {
          if (valid) {
            flag = true;
          }
        }
      );
      if (!flag) {
        return false;
      }

      let params = {};
      params = {
        ...this.$refs.addEditPopup.$refs.addPopForm.formData,
        id: this.pageProp.id,
      };
      this.$refs.addEditPopup.contarctList.forEach((item) => {
        if (params.saleContractCode != "") {
          if (item.code == params.saleContractCode) {
            params.saleContractId = item.id;
          }
        } else {
          params.saleContractId = "";
        }
      });
      this.$refs.addEditPopup.selfContracList.forEach((item) => {
        if (params.selfContractCode != "") {
          if (item.code == params.selfContractCode) {
            params.selfContractId = item.id;
          }
        } else {
          params.selfContractId = "";
        }
      });
      postApi(`/contract/specialrecord`, params).then((res) => {
        if (res.data.code == 0) {
          this.$message.success("操作成功");
        } else {
          this.$message.warning("操作失败，请稍后重试");
        }
        if (type == 1) {
          this.addEditPopupVisible = false;
        } else if (type == 0) {
          let basicFormData = {
            code: "",
            recordDate: this.$moment().format("YYYY-MM-DD"),
            selfContractName: "",
            selfContractCode: "",
            selfContractId: "",
            procurementPaymentAmount: null,
            recordUsedAmount: null,
            amount: null,
            partAId: "",
            remark: "",
            saleContractCode: "",
            saleContractId: "",
            saleContractName: "",
            foriginalamountd: null,
            saleUsedAmountd: null
          };
          this.$refs.addEditPopup.$refs.addPopForm.formData = basicFormData;
          this.pageProp.id = null;
        }
        this.getTableData();
      });
    },
  },
};
</script>
  
  <style lang="less" scoped></style>