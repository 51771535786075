import { render, staticRenderFns } from "./addEditPopup.vue?vue&type=template&id=3f3b8960&scoped=true"
import script from "./addEditPopup.vue?vue&type=script&lang=js"
export * from "./addEditPopup.vue?vue&type=script&lang=js"
import style0 from "./addEditPopup.vue?vue&type=style&index=0&id=3f3b8960&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f3b8960",
  null
  
)

export default component.exports